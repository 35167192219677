<template>
  <div class="update-trade-in">
    <div class="row">
      <div class="col-md-12">
        <b-overlay
          :show="isLoading"
          rounded
          opacity="0.6"
          class="d-inline-block"
          no-wrap
        >
          <template #overlay>
            <div class="d-flex align-items-center">
              <b-spinner
                variant="light"
                small
                type="grow"
              ></b-spinner>
            </div>
          </template>
        </b-overlay>
        <KTCodePreview v-bind:title="'Thông tin phiếu yêu cầu định giá'">
          <template v-slot:preview>
            <div class="col-md-12 mb-5">
              <div class="row pl-0">
                <div class="col-md-8">
                  <b-row class="mb-3">
                    <label
                      style="font-weight: 600"
                      for="input-status"
                      class="col-md-3 pl-0"
                    >Chương trình:</label>
                    <p class="badge mr-1 badge-secondary">{{valuationRequest.searchTradeInProgram}}</p>
                  </b-row>

                  <b-row class="mb-3">
                    <label
                      style="font-weight: 600"
                      for="input-status"
                      class="col-md-3 pl-0"
                    >Thông tin sản phẩm</label>
                    <b-row class="col-md-12 mb-3 m-0 p-0">
                      <b-col class="col-md-3 p-0">
                        <b-form-select
                          v-model="selectTypeSearch"
                          :options="listTypeSearch"
                          size="sm"
                          value-field="id"
                          text-field="name"
                          @change="changePlaceholder"
                          class="select-style"
                        ></b-form-select>
                      </b-col>
                      <b-col class="col-md-9 p-0">
                        <Autosuggest
                          :model="searchProductFor"
                          :suggestions="optionProducts"
                          placeholder="tên, mã, mã vạch sản phẩm"
                          :limit="10"
                          @select="onSelectedProductFor"
                          @change="onInputChangeProductFor"
                          suggestionName="productName"
                        >
                          <template #custom="{ suggestion }">
                            <div
                              style="display: flex; align-items: center"
                              class="font-weight-bold"
                            >
                              <div>
                                <!-- <span class="text-primary">({{ convertPrice(suggestion.item.sellingPrice) }})</span> -->
                                {{ suggestion.item.productName }}
                              </div>
                            </div>
                          </template>
                          <template #after-section>
                            <div
                              class="p-2 text-center"
                              v-if="!optionProducts.length"
                            >
                              <div v-if="!productLoading">
                                <img src="@/assets/image/no-results.png" />
                                <b class="ml-2">Không có kết quả</b>
                              </div>
                              <b-spinner
                                label="Spinning"
                                v-else
                              ></b-spinner>
                            </div>
                          </template>
                        </Autosuggest>
                      </b-col>
                    </b-row>
                    <table class="table table-bordered table-vertical-center table-hover">
                      <thead>
                        <tr>
                          <th class="headerTable">STT</th>
                          <th class="name headerTable">
                            Sản phẩm
                          </th>
                          <th class="value headerTable">SL</th>
                          <th class="code headerTable">Giá thu sản phẩm</th>
                          <!-- <th></th> -->
                        </tr>
                      </thead>
                      <tbody v-if="!productInValuationRequest || !productInValuationRequest.length">
                        <tr class="product-item">
                          <td
                            class="input-padding td-stt text-center"
                            colspan="5"
                          >
                            Vui lòng nhập sản phẩm
                          </td>
                        </tr>
                      </tbody>
                      <template v-else>
                        <tbody
                          v-for="(productItem, index) in productInValuationRequest"
                          :key="productItem.id"
                        >

                          <tr class="product-item">
                            <td class="input-padding td-stt">
                              <span>{{ index + 1 }}</span>
                            </td>
                            <td class="input-padding td-product">
                              <div class="mt-1 mb-2">
                                <p
                                  style="font-weight: 600; cursor: pointer"
                                  class="m-0 productName"
                                >
                                  {{ productItem.productName }}
                                </p>

                                <p
                                  style="font-weight: 600; cursor: pointer"
                                  class="m-0 productName"
                                  v-if="valuationRequest.searchImei"
                                >
                                  IMEI: {{ valuationRequest.searchImei }}
                                </p>
                              </div>
                            </td>
                            <td class="input-padding td-quantity">
                              <span>{{ productItem.quantity }}</span>
                            </td>
                            <td class="input-padding td-product-price">
                              <span>
                                {{ convertPrice(productItem.productBuyingPrice) }}
                              </span>
                            </td>
                            <!-- <td class="text-center">
                              <span
                                class="change-product-icon cursor-pointer"
                                title="Đổi sản phẩm để định giá thu cũ"
                              >
                                <i
                                  style="font-size: 1rem; color: #d33"
                                  class="flaticon2-rubbish-bin-delete-button"
                                  @click="onRemoveProductRequest"
                                ></i>
                              </span>
                            </td> -->
                          </tr>
                        </tbody>
                      </template>
                    </table>

                  </b-row>
                  <!-- <b-row>
                    <b-col col="12">
                      <hr
                        class="hr-text"
                        data-content="Thông tin sản phẩm định giá thu cũ"
                        style="font-weight: 600"
                      />
                    </b-col>
                  </b-row> -->

                  <!-- <b-row class="mb-3">
                    <label
                      style="font-weight: 600"
                      for="input-status"
                      class="col-md-5 pl-0"
                    >Liên kết sản phẩm để định giá thu cũ</label>
                    <b-row class="col-md-12 mb-3 m-0 p-0">
                      <b-col class="col-md-3 p-0">
                        <b-form-select
                          v-model="selectTypeSearch"
                          :options="listTypeSearch"
                          size="sm"
                          value-field="id"
                          text-field="name"
                          @change="changePlaceholder"
                        ></b-form-select>
                      </b-col>
                      <b-col class="col-md-9 p-0">
                        <vue-autosuggest
                          v-model="searchProduct"
                          :suggestions="filteredOptionsProduct"
                          @selected="onSelectedProduct"
                          :limit="20"
                          @input="onInputChangProduct"
                          :input-props="inputSearchProductProp"
                          :should-render-suggestions="
                        (size, loading) =>
                          size >= 0 && !loading && searchProduct !== ''
                      "
                        >
                          <div
                            v-if="selectTypeSearch === 1"
                            slot-scope="{ suggestion }"
                            style="display: flex; align-items: center"
                          >
                            <div>
                              <span class="text-primary">({{ formatMoney(suggestion.item.sellingPrice) }})
                                (</span>
                              <span
                                v-if="suggestion.item.quantityInStock > 0"
                                class="text-success"
                              >Tồn TCB:
                                {{ suggestion.item.quantityInStock }}</span>
                              <span
                                v-else
                                class="text-danger"
                              >Tồn TCB:
                                {{ suggestion.item.quantityInStock }}</span>
                              )
                              {{ suggestion.item.productName }}
                            </div>
                          </div>
                          <div
                            v-else-if="selectTypeSearch === 2"
                            slot-scope="{ suggestion }"
                            style="display: flex; align-items: center"
                          >
                            <div>
                              <span class="text-primary">({{
                              formatMoney(suggestion.item.sellingPrice)
                            }})</span>
                              {{ suggestion.item.productName }}-
                              <span class="text-warning">IMEI: {{ suggestion.item.imeiCode }}</span>
                            </div>
                          </div>
                        </vue-autosuggest>
                      </b-col>
                    </b-row>
                    <table class="table table-bordered table-vertical-center table-hover">
                      <thead>
                        <tr>
                          <th class="headerTable">STT</th>
                          <th class="name headerTable">
                            Sản phẩm
                          </th>
                          <th class="value headerTable">SL</th>
                          <th class="code headerTable">Giá thu sản phẩm</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody
                        v-for="(productItem, index) in productInValuationRequest"
                        :key="productItem.id"
                      >
                        <tr class="product-item">
                          <td class="input-padding td-stt">
                            <span>{{
        index + 1
      }}</span>
                          </td>
                          <td class="input-padding td-product">
                            <div class="mt-1 mb-2">
                              <p
                                style="font-weight: 600; cursor: pointer"
                                class="m-0 productName"
                              >
                                {{ productItem.productName }}
                              </p>

                              <p
                                style="font-weight: 600; cursor: pointer"
                                class="m-0 productName"
                                v-if="valuationRequest.searchImei"
                              >
                                IMEI: {{ valuationRequest.searchImei }}
                              </p>
                            </div>
                          </td>
                          <td class="input-padding td-quantity">
                            <span>{{ productItem.quantity }}</span>
                          </td>
                          <td class="input-padding td-product-price">
                            <span>
                              {{ convertPrice(productItem.productBuyingPrice) }}
                            </span>
                          </td>
                          <td class="text-center">
                            <span
                              class="change-product-icon cursor-pointer"
                              title="Đổi sản phẩm để định giá thu cũ"
                            >
                              <em
                                class="fas fa-sync-alt icon-color"
                                style="font-size: 1rem;"
                              ></em>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </b-row> -->

                  <b-row
                    class="mb-3"
                    v-for="(item, index) in valuationRequest.listGroupOption"
                    :key="index"
                  >

                    <label
                      style="font-weight: 600"
                      for="input-status"
                      class="col-12 pl-0"
                    >{{ item.criterialName }} ({{item.type === 1 ? "1 lựa chọn":  "Nhiều lựa chọn" }})</label>
                    <b-table
                      class="table-bordered table-hover col-md-12"
                      :fields="computedFields"
                      :items="item.listItem"
                    >
                      <template v-slot:cell(amount)="row">
                        <div class="row d-flex justify-content-end">
                          <div class="d-flex justify-content-end pr-2">
                            <span v-if="row.item.operatorType === 1">(+)
                            </span>
                            <span v-if="row.item.operatorType === 2">(-)
                            </span>
                            <span v-text="convertPrice(row.item.amount)"></span>
                          </div>
                        </div>
                      </template>

                      <template v-slot:cell(selected)="row">
                        <div class="text-center h-100">
                          <b-form-checkbox
                            v-if="row.item.optionType === 2"
                            :id="`${row.item.id}`"
                            v-model="row.item.selected"
                          >
                          </b-form-checkbox>
                          <b-form-radio
                            v-else-if="row.item.optionType === 1"
                            v-model="row.item.selected"
                            :name="`${item.id}_${item.type}`"
                            :id="`${row.item.id}`"
                            :value="row.item.id"
                            @click.native.prevent="handleRadioChange(item, row.item)"
                          ></b-form-radio>
                          <!-- <b-form-radio
                            v-else-if="row.item.optionType === 1"
                            v-model="row.item.selected"
                            :name="`${item.id}_${item.type}`"
                            :value="row.item.id"
                            :id="`${row.item.id}`"
                          ></b-form-radio> -->
                        </div>
                      </template>
                      <template v-slot:cell(adjustAmount)="row">
                        <div class="row d-flex justify-content-end">
                          <div class="d-flex justify-content-end pr-2">
                            <span v-if="row.item.adjustOperatorType === 1">(+)
                            </span>
                            <span v-if="row.item.adjustOperatorType === 2">(-)
                            </span>
                            <span v-text="convertPrice(row.item.adjustAmount)"></span>
                          </div>
                        </div>
                      </template>
                    </b-table>
                  </b-row>
                </div>
                <div class="col-md-4">
                  <div class="row pl-9 mb-4">
                    <label
                      style="font-weight: 600"
                      for="input-status"
                      class="col-md-4 pl-0"
                    >Cửa hàng:</label>
                    <b-col
                      md="12"
                      class="px-0"
                    >
                      <Autosuggest
                        :model="valuationRequest.searchStore"
                        :suggestions="filteredStoreOptions"
                        :placeholder="'tên cửa hàng'"
                        :limit="10"
                        @select="onSelectedStore"
                        @change="onInputChangeStore"
                      />
                    </b-col>
                  </div>
                  <div class="row pl-9 mb-4">
                    <label
                      style="font-weight: 600"
                      for="input-status"
                      class="col-md-4 pl-0"
                    >Khách hàng:</label>
                    <b-col
                      md="12"
                      class="px-0"
                    >
                      <Autosuggest
                        :model="valuationRequest.searchCustomer"
                        :suggestions="filteredOptionsCustomer"
                        :placeholder="'tên, SĐT khách hàng'"
                        :limit="10"
                        @select="onSelectedCustomer"
                        @change="onInputChangeCustomer"
                      />
                    </b-col>
                  </div>
                  <!-- <div class="row pl-9">
                    <label
                      style="font-weight: 600"
                      for="input-status"
                      class="col-md-4 pl-0"
                    >Ngày tạo:</label>
                    <span class="ml-4">{{ valuationRequest.createdAt }}</span>
                  </div> -->
                  <!-- <div class="row pl-9">
                    <label
                      style="font-weight: 600"
                      for="input-status"
                      class="col-md-4 pl-0"
                    >Người tạo:</label>
                    <span class="ml-4">{{ valuationRequest.createdBy }}</span>
                  </div> -->
                  <div class="row pl-9 mb-4">
                    <label
                      style="font-weight: 600"
                      for="input-status"
                      class="col-md-4 pl-0"
                    >Trạng thái:</label>
                    <b-col
                      md="12"
                      class="px-0"
                    >
                      <b-form-select
                        class="select-style"
                        size="sm"
                        id="input-3"
                        v-model="valuationRequest.selectedStatus"
                        :options="VALUATION_REQUEST_STATUS_OPTION"
                        required
                        value-field="id"
                        text-field="name"
                        disabled-field="notEnabled"
                      ></b-form-select>
                    </b-col>
                  </div>
                  <div
                    class="row pl-9 mb-4"
                    v-if="valuationRequest.selectedStatus == VALUATION_REQUEST_STATUS.CANCEL"
                  >
                    <label
                      style="font-weight: 600"
                      for="input-status"
                      class="col-md-4 pl-0"
                    >Lí do hủy:</label>
                    <b-col
                      md="12"
                      class="px-0"
                    >
                      <b-form-textarea
                        id="textarea-description"
                        v-model="valuationRequest.cancelNote"
                        placeholder="Ghi chú"
                        rows="5"
                      ></b-form-textarea>
                    </b-col>
                  </div>
                  <div class="row pl-9 my-7">
                    <p class="dashed w-100 m-0"></p>
                  </div>
                  <div class="row pl-9 mb-1">
                    <p class="font-size-h4 font-weight-bolder">Tổng quan</p>
                  </div>
                  <div class="row pl-9 mb-4">
                    <label
                      style="font-weight: 600"
                      for="input-status"
                      class="col-md-7 pl-0"
                    >Giá thu trên sản phẩm:</label>
                    <span>{{ convertPrice(valuationRequest.productBuyingPrice) }}</span>
                  </div>
                  <div class="row pl-9 mb-4">
                    <label
                      style="font-weight: 600"
                      for="input-status"
                      class="col-md-7 pl-0"
                    >Phát sinh sau đánh giá:</label>
                    <span>{{ convertPrice(valuationRequest.totalCriteriaPrice) }}</span>
                  </div>
                  <div class="row pl-9 mb-4">
                    <label
                      style="font-weight: 600"
                      for="input-status"
                      class="col-md-7 pl-0"
                    >Giá thu dự kiến:</label>
                    <span>{{ convertPrice(valuationRequest.estimationBuydingPrice) }}</span>
                  </div>
                  <div class="row pl-9 mb-4">
                    <label
                      style="font-weight: 600"
                      for="input-status"
                      class="col-md-7 pl-0"
                    >Giá thu cuối cùng:</label>
                    <span>{{ convertPrice(finalBuyingPrice) }}</span>
                  </div>
                  <div class="row pl-9 mb-4">
                    <label
                      style="font-weight: 600"
                      for="input-description"
                    >Ghi chú:</label>
                  </div>
                  <div class="row pl-9 mb-4">
                    <b-form-textarea
                      id="textarea-description"
                      v-model="valuationRequest.note"
                      placeholder="Ghi chú"
                      rows="5"
                    ></b-form-textarea>
                  </div>
                  <div class="row pl-9 mb-4">
                    <div class="mb-5 mt-5">
                      <button
                        type="button"
                        class="btn btn-primary font-weight-bolder btn-sm button mr-4"
                        @click="onSubmit"
                      >
                        Lưu phiếu
                      </button>
                      <!-- <button
                        type="button"
                        class="btn btn-primary font-weight-bolder btn-sm button"
                        @click="importTradeIn"
                      >
                        Tạo phiếu nhập định giá
                      </button> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:foot>
            <router-link
              to="/trade-in"
              tag="button"
            >
              <b-button
                style="font-weight: 600; width: 70px"
                variant="secondary"
                size="sm"
              >Hủy</b-button>
            </router-link>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import { validationMixin } from 'vuelidate';
import ApiService from '@/core/services/api.service';
import decounce from 'debounce';
import { TIME_TRIGGER } from '@/utils/constants';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import { convertPrice } from '@/utils/common';
import { VALUATION_REQUEST_STATUS_OPTION } from '@/utils/constants';
import { VALUATION_REQUEST_STATUS } from '@/utils/enum';
import { getToastInstance } from '@/utils/toastHelper';
import toNumber from 'lodash/toNumber';
import cloneDeep from 'lodash/cloneDeep';
import UpdateValuationRequestReqModel from '@/view/pages/valuation-request/models/UpdateValuationRequestReqModel';
import { showConfirmNotify } from '@/utils/sweet-alert2';

export default {
  mixins: [validationMixin],
  data() {
    return {
      productLoading: false,
      valuationRequest: {
        searchTradeInProgram: '',
        searchProduct: '',
        searchCustomer: '',
        customerId: null,
        searchStore: '',
        searchImei: '',
        selectedStatus: 1,
        createdBy: null,
        createdAt: null,
        listGroupOption: [],
        productBuyingPrice: 0,
        totalCriteriaPrice: 0,
        estimationBuydingPrice: 0,
        finalBuyingPrice: 0,
        note: '',
        cancelNote: '',
      },
      valid: true,
      form: {
        username: '',
        password: '',
      },
      selectedEmployee: null,
      VALUATION_REQUEST_STATUS_OPTION,
      VALUATION_REQUEST_STATUS,
      selectedRole: null,
      listRole: [],
      selectedId: null,
      isNew: true,
      searchEmployee: '',
      filteredOptions: [],
      options: [
        {
          data: [],
        },
      ],
      listTradeInProgram: [
        {
          id: 1,
          name: 'AAA',
        },
        {
          id: 2,
          name: 'BBBB',
        },
      ],
      selectedTradeInProgram: 1,
      filteredOptionsProduct: [],
      optionsProduct: [
        {
          data: [],
        },
      ],
      filteredOptionsTradeInProgram: [],
      optionsTradeInProgram: [
        {
          data: [],
        },
      ],
      filteredOptionsCustomer: [],
      optionsCustomer: [
        {
          data: [],
        },
      ],
      filteredStoreOptions: [],
      optionsStore: [],
      filteredOptionsImei: [],
      optionsImei: [
        {
          data: [],
        },
      ],
      tradeInId: null,
      listCriterialOption: [],
      fields: [
        {
          key: 'name',
          label: 'Tiêu chí',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '50%',
          },
          tdClass: 'text-left',
          enableFeature: false,
        },
        {
          key: 'selected',
          label: 'Lựa chọn',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '20%',
          },
          tdClass: 'text-left',
          enableFeature: false,
        },
        {
          key: 'amount',
          label: 'Giá trị',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '15%',
          },
          tdClass: 'text-left',
          enableFeature: false,
        },
        {
          key: 'adjustAmount',
          label: 'Giá trị 2',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '15%',
          },
          tdClass: 'text-left',
          enableFeature: true,
        },
      ],
      isDisabled: false,
      renderComponent: false,
      isLoading: false,
      productInValuationRequest: [],
      selectTypeSearch: 1,
      searchProduct: '',
      searchProductFor: '',
      listTypeSearch: [
        {
          id: 1,
          name: 'Tìm sản phẩm imei',
        },
      ],
      filteredProducts: [],
    };
  },
  validations: {
    form: {},
  },
  components: {
    KTCodePreview,
    Autosuggest,
  },
  async created() {
    this.tradeInId = this.$route.query.id;
    await this.onInitData();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Thu cũ- đổi mới', route: '/trade-in' },
      { title: 'Danh sách phiếu thu cũ', route: '/trade-in' },
      { title: 'Thông tin phiếu yêu cầu định giá' },
    ]);
  },
  methods: {
    convertPrice,
    ...getToastInstance(),
    async onInitData() {
      if (this.isLoading) return;
      this.isLoading = true;
      try {
        const [tradeIn, stores] = await Promise.all([
          this.getTradeInById(),
          this.fetchStore(),
        ]);
        this.valuationRequest = {
          tradeInProgramId: tradeIn.tradeInProgramId || '',
          searchTradeInProgram: tradeIn.tradeInProgramName || '',
          searchProduct: tradeIn.productName || '',
          searchCustomer: tradeIn.customerName || '',
          customerId: tradeIn.customerId,
          searchStore: tradeIn.storeName || '',
          searchImei: tradeIn.imeiCode || '',
          selectedStatus: tradeIn.status,
          createdBy: tradeIn.createdBy,
          createdAt: tradeIn.createdAt,
          listGroupOption: tradeIn.listCreteriaGroup,
          productBuyingPrice: tradeIn.productBuyingPrice,
          totalCriteriaPrice: tradeIn.totalCriteriaPrice,
          estimationBuydingPrice: tradeIn.estimationBuydingPrice,
          finalBuyingPrice: tradeIn.finalBuyingPrice,
          note: tradeIn.note,
          cancelNote: tradeIn.cancelNote,
          storeId: tradeIn.storeId,
        };
        this.productInValuationRequest = [
          {
            id: tradeIn.id,
            productId: tradeIn.productId,
            productName: tradeIn.productName,
            quantity: 1,
            productBuyingPrice: tradeIn.productBuyingPrice,
            imeiNo: tradeIn.imeiNo,
          },
        ];
        this.storeOptions = stores.map((item) => {
          return {
            id: item.id,
            name: item.name,
            shortName: item.shortName,
            suggestionName: item.shortName + ' ( ' + item.name + ')',
          };
        });

        this.filteredStoreOptions = [...this.storeOptions];

        this.$nextTick(() => {
          this.isLoading = false;
        });
      } catch (error) {
        this.makeToastFailure(error.message);
      }
    },
    async fetchStore() {
      const response = await ApiService.get('/stores/getStores');
      return response.data.data;
    },
    onInputChange(text) {
      if (text === '') {
        this.inputSearchProductProp.disabled = true;
      }
      this.valuationRequest.searchStore = text;
      const filteredData = this.options[0].data
        .filter((item) => {
          return item.name.toLowerCase().indexOf(text.toLowerCase()) > -1;
        })
        .slice(0, this.limit);
      this.filteredOptions = [
        {
          data: filteredData,
        },
      ];
    },
    async onSelectedProductFor(option) {
      if (this.isLoading) return;
      this.isLoading = true;

      const clonedValuationRequest = cloneDeep(this.valuationRequest);
      try {
        const params = {
          productId: option.item.id,
        };
        const response = await ApiService.query(
          `/tradeInProgram/mobile/by-product-id`,
          {
            params,
          },
        );

        const content = {
          title: `Chỉnh sửa sản phẩm định giá`,
          html: `Bạn có chắc muốn chỉnh sửa sản phẩm định giá ?  <p class="font-weight-bold font-italic text-warning">Lưu ý: sau khi xác nhận những thông tin về tiêu chí đánh giá sẽ được làm mới</p>`,
          actionName: 'Xác nhận',
          confirmButtonColor: '#3699FF',
        };
        const result = await showConfirmNotify(content);
        if (!result.value) {
          this.isLoading = false;
          return;
        }

        this.searchProductFor = '';
        this.filteredProducts = [];

        this.productInValuationRequest = [
          {
            id: option.item.id,
            productId: option.item.id,
            productName: option.item.productName,
            quantity: 1,
            productBuyingPrice: response.data.data.buyingPrice,
            productType: option.item.productType,
            imeiNo: option.item.imeiNo,
          },
        ];

        this.valuationRequest = {
          ...clonedValuationRequest,
          productBuyingPrice: response.data.data.buyingPrice,
          totalCriteriaPrice: response.data.data.totalCriteriaPrice,
          estimationBuydingPrice: response.data.data.estimationBuydingPrice,
          finalBuyingPrice: response.data.data.finalBuyingPrice,
          tradeInProgramName: response.data.data.name,
          tradeInProgramId: response.data.data.id,
          searchTradeInProgram: response.data.data.name,
          listGroupOption: response.data.data.tradeInProgramCriteriaGroup.map(
            (item) => {
              const listItem = item.tradeInProgramCreteriaGroupOptions.map(
                (option) => ({
                  id: option.id,
                  priority: option.priority,
                  name: option.creteriaName,
                  operatorType: option.operator,
                  adjustOperatorType: option.adjustOperator,
                  amount: option.amount,
                  adjustAmount: option.adjustAmount,
                  description: option.description,
                  selected: false,
                  optionType: item.type,
                }),
              );
              return {
                id: item.id,
                priority: item.priority,
                criterialName: item.criteriaGroupName,
                type: item.type,
                listItem: listItem,
              };
            },
          ),
        };

        this.$nextTick(() => {
          this.isLoading = false;
        });

      } catch (error) {
        this.isLoading = false;
        this.makeToastFailure(error.message);
      }
    },
    onInputChangProduct(text) {
      this.listProduct = [];
      this.optionsProduct[0].data = [];
      this.filteredOptionsProduct = [
        {
          data: this.optionsProduct[0].data,
        },
      ];
      if (!text) {
        text = '';
      }
      this.searchProduct = text.trim();
      this.debounceInputProduct();
    },
    debounceInputFor: decounce(function () {
      if (!this.isSearching) {
        let textSearch = this.searchProductFor;
        this.fetchProduct(textSearch);
      }
    }, TIME_TRIGGER),
    fetchProduct(textSearch) {
      this.isSearching = true;
      this.filteredProducts = [];

      const params = {
        productName: textSearch ? textSearch.trim() : null,
      };
      ApiService.query(`/tradeInProgram/mobile/by-product-name`, {
        params,
      }).then((response) => {
        this.filteredProducts = response.data.data;
        this.isSearching = false;
        this.productLoading = false;

        if (textSearch !== this.searchProductFor) {
          this.searchProductAPI();
        }
      });
    },
    searchProductAPI() {
      if (!this.isSearching) {
        const textSearch = this.searchProductFor
          ? this.searchProductFor.trim()
          : '';
        if (!textSearch) {
          return;
        }
        this.fetchProduct(textSearch);
      }
    },
    debounceInputProduct: decounce(function () {
      this.searchProductAPI();
    }, TIME_TRIGGER),
    onSelectedTradeInProgram(option) {
      this.valuationRequest.searchTradeInProgram = option.item.name;
    },
    onInputChangeTradeInProgram(text) {
      if (!text) {
        text = '';
      }
      this.valuationRequest.searchTradeInProgram = text;
    },
    onSelectedCustomer(option) {
      this.valuationRequest.searchCustomer = option.item.name;
    },
    onInputChangeCustomer(text) {
      if (!text) {
        text = '';
      }
      this.valuationRequest.searchCustomer = text;
    },
    onSelectedStore(option) {
      this.valuationRequest.searchStore = option.item.name;
      this.valuationRequest.storeId = option.item.id;
    },
    onInputChangeStore(text) {
      if (!text) {
        text = '';
      }
      this.valuationRequest.searchStore = text;

      const filteredData = this.storeOptions
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, 10);

      this.filteredStoreOptions = [...filteredData];
    },
    onInputChangeImei(text) {
      if (!text) {
        text = '';
      }
      this.valuationRequest.searchImei = text;
    },
    debounceInput: decounce(function () {
      if (!this.isSearching) {
        let textSearch = this.valuationRequest.searchProduct;
        this.fetchProduct(textSearch);
      }
    }, TIME_TRIGGER),
    async getTradeInById() {
      const response = await ApiService.get(
        `valuation-requests/${this.$route.query.id}`,
      );
      return response.data.data;
    },
    importTradeIn() {
      const { href } = this.$router.resolve({
        name: 'update-trade-in',
        query: { valuationRequestId: this.$route.query.id },
      });
      window.open(href, '_blank');
    },
    async onSubmit() {
      if (this.isLoading) return;
      this.isLoading = true;
      await this.$nextTick(() => {});
      try {
        const criteriaGroups = this.getSelectedCriteriaGroups();

        const data = new UpdateValuationRequestReqModel({
          ...this.valuationRequest,
          id: this.valuationRequestId,
          productId: this.productInValuationRequest[0].productId,
          imeiNo: this.productInValuationRequest[0].imeiNo,
          criteriaGroups: criteriaGroups,
          status: this.valuationRequest.selectedStatus,
        });

        const res = await ApiService.put('valuation-requests', data);
        this.makeToastSuccess(res.data.message);
        this.$router.push({
          name: 'list-valuation-request',
        });
      } catch (error) {
        const message = error.response.data.message;
        this.makeToastFailure(message);
      } finally {
        this.isLoading = false;
      }
    },
    getSelectedCriteriaGroups() {
      return this.valuationRequest.listGroupOption.flatMap((item) => {
        return item.listItem.reduce((result, i) => {
          if (i.selected) {
            result.push({
              id: i.id,
              tradeInProgramCreteriaGroupId: item.id,
              creteriaName: i.name,
              description: i.description,
              priority: i.priority,
              operator: i.operatorType,
              amount: i.amount,
              adjustAmount: i.adjustAmount,
              adjustOperator: i.adjustOperatorType,
            });
          }
          return result;
        }, []);
      });
    },
    handleRadioChange(selectedGroupOption, selectedGroupOptionItem) {
      this.valuationRequest.listGroupOption.forEach((group) => {
        if (group.id === selectedGroupOption.id) {
          group.listItem.forEach((item) => {
            item.selected =
              item.id === selectedGroupOptionItem.id && !item.selected
                ? item.id
                : false;
          });
        }
      });
    },
    toggleRadio(rowItem) {
      if (rowItem.selected === rowItem.id) {
        rowItem.selected = false;
      } else {
        rowItem.selected = rowItem.id;
      }
    },
    changePlaceholder() {
      if (this.selectTypeSearch === 1) {
        this.inputSearchProductProp.placeholder =
          'Tìm kiếm theo tên, mã, mã vạch sản phẩm';
      } else if (this.selectTypeSearch === 2) {
        this.inputSearchProductProp.placeholder =
          'Tìm kiếm theo mã IMEI sản phẩm';
      }
      this.optionsProduct[0].data = [];
    },
    async onSelectedProduct(option) {
      this.searchProduct = '';
      this.selectedProduct = option.item;
      this.listBillItem = this.sortListBillItem(this.listBillItem);
      this.optionsProduct[0].data = [];
    },
    onInputChangeProductFor(text) {
      if (!text) {
        text = '';
        this.productLoading = false;
        return;
      }
      this.productLoading = true;
      this.searchProductFor = text.trim();
      this.debounceInputFor();
    },
    onRemoveProductRequest() {
      this.productInValuationRequest = [];
    },
  },
  watch: {
    totalCriteriaPrice: {
      handler(newVal, val) {
        if (newVal !== val) {
          this.valuationRequest.totalCriteriaPrice = newVal;
        }
      },
      immediate: true,
    },
    finalBuyingPrice: {
      handler(newVal, val) {
        if (newVal !== val) {
          this.valuationRequest.finalBuyingPrice = newVal;
        }
      },
      immediate: true,
    },
  },
  computed: {
    valuationRequestId() {
      return this.$route.query.id;
    },
    computedFields() {
      return this.fields;
    },
    groupOptionItems() {
      return (this.valuationRequest.listGroupOption || []).reduce(
        (result, item) => {
          const listItem = item.listItem;

          if (listItem && listItem.length) {
            result.push(...listItem);
          }

          return result;
        },
        [],
      );
    },
    totalCriteriaPrice() {
      const listGroupOption = (this.groupOptionItems || []).reduce(
        (result, item) => {
          const selected = item.selected;
          if (!selected) return result;

          switch (item.operatorType) {
            case 1: {
              result = result + item.amount;
              break;
            }
            case 2: {
              result = result - item.amount;
              break;
            }
          }
          switch (item.adjustOperatorType) {
            case 1: {
              result = result + item.adjustAmount;
              break;
            }
            case 2: {
              result = result - item.adjustAmount;
              break;
            }
          }

          return result;
        },
        0,
      );
      return listGroupOption;
    },
    finalBuyingPrice() {
      const value =
        toNumber(this.valuationRequest.productBuyingPrice) +
        toNumber(this.totalCriteriaPrice);

      return value > 0 ? value : 0;
    },
    optionProducts() {
      return cloneDeep(this.filteredProducts);
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/sass/components/_variables.bootstrap.scss';
.update-trade-in {
  input::-webkit-calendar-picker-indicator {
    display: none;
  }

  .label-input {
    font-weight: 500;
  }

  .dashed {
    border: 1px dashed $gray-700;
    border-style: none none dashed;
    background-color: #fff;
  }

  .custom-checkbox {
    .custom-control-label {
      &::before {
        border-radius: 0;
      }
    }
  }
}
</style>
