export default class UpdateValuationRequestReqModel {
  constructor(data = {}) {
    this.id = data.id;
    this.tradeInProgramId = data.tradeInProgramId;
    this.productId = data.productId;
    this.imeiNo = data.imeiNo;
    this.productBuyingPrice = data.productBuyingPrice;
    this.totalCriteriaPrice = data.totalCriteriaPrice;
    this.finalBuyingPrice = data.finalBuyingPrice;
    this.customerId = data.customerId;
    this.note = data.note;
    this.cancelNote = data.cancelNote;
    this.status = data.status;
    this.storeId = data.storeId;
    this.criteriaGroups = data.criteriaGroups.map(
      (item) => new CriteriaGroup(item),
    );
  }
}

class CriteriaGroup {
  constructor(data) {
    this.id = data.id;
    this.tradeInProgramCreteriaGroupId = data.tradeInProgramCreteriaGroupId;
    this.creteriaName = data.creteriaName;
    this.description = data.description;
    this.priority = data.priority;
    this.amount = data.amount;
    this.operator = data.operator;
    this.productComponentCategoryId = data.productComponentCategoryId;
    this.adjustAmount = data.adjustAmount;
    this.adjustOperator = data.adjustOperator;
  }
}
